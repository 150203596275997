@font-face {
  font-family: "anticslab";
  src: url(fonts/AnticSlab-Regular.ttf);
}
@font-face {
  font-family: "dmserif";
  src: url(fonts/DMSerifDisplay-Regular.ttf);
}

body {
  margin: 0;
  font-family: "acumin-pro-wide", "Montserrat", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/** {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}*/

* {
  line-height: 1.6;
  font-family: "dmserif", "garamomd", "serif";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.fa {
  padding: 20px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
  color: black;
}

.fa:hover {
  color: grey;
}
.nav-background {
  background-color: rgba(255, 0, 0, 0.8);
}
.img-historia-yoly {
  width: 240px;
  height: 240px;
  object-fit: cover;
  border-radius: 50%;
}
.img-portada-yoly {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
}
.texto-salmon {
  color: #ffa680;
}
.historia-texto {
}
.historia-texto p {
  font-size: 18px;
  padding: 10px;
  padding: 4px;
}
.historia-texto h2 {
  font-size: 32px;
  padding: 10px;
}
.texto-titulo {
  font-size: 40px;
}

.marco {
  width: 100%;
  height: 100%;
  opacity: 30%;
  transition: 0.3s;
}
.marco:hover {
  border: solid salmon 10px;
}

.home-imagen-receta {
  width: 240px;
  height: 350px;
  object-fit: cover;
}
.home-receta-cuadro h4 {
  max-width: 240px;
}

.link-clean {
  text-decoration: none;
  color: inherit;
}

.hero-image {
  height: 500px;
}
.hero-image-img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.hero-gradient {
  height: 80%;

  background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.8));
}
.marco-50 {
  width: 60%;
  border-top: solid rgba(255, 255, 255, 0.5) 2px;
}
.titulo-texto {
  font-size: 40px;
}
.cerca {
  top: 600px;
}
.imagen-lateral {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.iframe-yt {
  height: 315px;
  width: 560px;
}
.imagen-portada {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.posicion-logo {
  bottom: -100px;
  left: 50px;
}
.imagen-connect {
  width: 100%;
  max-height: 200px;
}
.logo{
  width:70px;
  height:70px;
  font-size: 35px;

}
@media screen and (max-width: 992px) {
  .iframe-yt {
    height: 300px;
    width: 600px;
  }
  .imagen-lateral {
    width: 100%;
    height: 300px;
  }

  .imagen-portada {
    max-height: 250px;
  }

}
@media screen and (max-width: 600px) {
  .iframe-yt {
    height: 175px;
    width: 330px;
  }
  .home-imagen-receta {
    min-width: 350px;
    height: 110px;
    object-fit: cover;
  }
  .imagen-lateral {
    height: 200px;

  }

  .imagen-portada {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: 50% 50%;
  }
  .posicion-logo {
    bottom: -110px;
    width: 100%;
    left: 0;
    text-align: center;
  }
}
